<template>
    <div v-if="mbundle.description" class="mbundle-description">
        <span v-html="mbundle.description"></span>
    </div>
</template>

<script>
export default {
    name: 'SpaceSingleMbundleHome',
    props: {
        mbundle: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            labels: {}
        }
    },
    computed: {
        
    },
    methods: {
        
    },
    components: {
        
    }
}
</script>

<style scoped lang="scss">
    @import '../../../styles/spaces_common.scss';

    .mbundle-description {
        background-color: #fff;
        box-shadow: $box_shadow;
        padding: 1rem;
    }
</style>